import React from 'react'
import { graphql } from 'gatsby'
import ComponentsManager from '../utils/components-manager'
import Layout from '../layout/layout'
import SEO from "./seo";

class SchoolPage extends React.Component {

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      // googleAnalyticsId must be a string of the following format: 'UA-xxxxxxxxx-x' where x is a number
      const googleAnalyticsId = this.props.data.nodeSchool.relationships.field_site_update.field_su_ga_id;
      if (googleAnalyticsId) {
        this.googleAnalyticsScript = document.createElement('script');
        this.googleAnalyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
        this.collegeAnalyticsScript = document.createElement('script');
        this.collegeAnalyticsScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${googleAnalyticsId}');`;
        window.document.body.append(this.googleAnalyticsScript, this.collegeAnalyticsScript);
      }
    }
  };

  componentWillUnmount = () => {
    if (this.googleAnalyticsScript) {
      this.googleAnalyticsScript.remove();
      this.collegeAnalyticsScript.remove();
    }
  };

  render = () => {
    let node = this.props.data.nodeSchool;

    const isInverted = (node.relationships.field_site_update.relationships.field_school_hero === null);


    return(
        <Layout invertHeader={isInverted}>
          <SEO title={'Apply to ' + node.title} description={node.relationships.field_site_update.field_su_tb_about_us} />
          <div>
            <ComponentsManager node={node} components={node.relationships.field_components} />
          </div>
        </Layout>
    );
  };
};
export default SchoolPage

/**
 * Query school page for all components, rest of data is fetched
 *
 * @NOTE Don't forget to wire component fragments!
 */
export const SchoolPageQuery = graphql`
query SchoolQuerySingle($drupalNodeId: Int!) {
  nodeSchool(drupal_internal__nid: {eq: $drupalNodeId})
  {
    title
    field_filter_aanapisi
    field_filter_aanhi
    field_filter_hbcu
    field_filter_hsi
    field_filter_nasnti
    field_filter_pbi
    field_invert_header_colors
    field_acc_region
    field_acc_md_fy_participation
    field_acc_md_tr_participation
    field_community_college
    field_member_id
    field_member_id_apply
    field_suppress_youvisit
                    
    relationships {
                      
      field_site_update {
        field_su_lat
        field_su_lng
        field_su_addr_country
        field_su_addr_street
        field_su_addr_street_2
        field_su_addr_city
        field_su_addr_state
        field_su_addr_state_full
        field_su_addr_zip
        field_su_addr_adm_phone
        field_su_addr_adm_email
        field_su_addr_adm_website
        field_su_addr_fy_adm_website
        field_su_addr_tr_adm_website
        field_su_addr_fy_finaid_website
        field_su_addr_tr_finaid_website
        field_su_addr_ts_daca
        field_transfer_guaranteed_admiss
        field_su_addr_fy_daca
        field_su_social_media_facebook
        field_su_social_media_twitter
        field_su_social_media_blog
        field_su_social_media_youtube
        field_su_social_media_instagram
        field_su_tb_about_us
        field_su_tb_additional_info {
          value
          processed
        }
        field_su_ipeds
        field_su_details_student_exp_tag
        field_su_details_aca_prog_tag
        field_su_tb_applying_to_fy
        field_su_tb_applying_to_tr
        field_su_tb_visiting_us
        field_su_tb_quote_text
        field_su_tb_quote_first_name
        field_su_tb_quote_last_name
        field_su_tb_quote_position
        field_su_tb_quote_deparment
        field_su_details_campus_setting
        field_su_details_type
        field_su_details_enroll_size
        field_su_details_enroll_comp
        field_su_affiliated_colleges
        
        field_su_details_student_exp_t_o
        field_su_details_aca_prog_tag_o
        field_su_alternate_names
        
        field_su_ff_fy_per
        field_su_ff_fy_tp
        field_su_ff_fy_caf
        field_su_ff_fy_srs
        field_su_ff_fy_rec_req
        field_su_ff_tr_per
        field_su_ff_tr_gap
        field_su_ff_tr_tp
        field_su_ff_tr_caf
        field_su_ff_tr_srs
        field_su_ff_tr_rec_req
        field_su_ga_id
        field_su_webadm_id
        
        
        relationships {
            field_su_img_logo {
              localFile {
                publicURL
              }
            }
            field_su_gallery {
                localFile {
                  publicURL
                }
            }
            field_school_hero {
                localFile {
                  publicURL
                }
            }
            field_su_explore_thumbnail {
                localFile {
                  publicURL
                }
            }
        }
        
      }
      
      field_membership_profile {
        field_membership_member_id
        field_mp_membership_year
        field_mp_fac_merit_fa
        field_mp_fac_need_fa
        field_mp_fac_international_fa
      }

      field_components {
        __typename

        ...ParagraphsSchoolLayout

      }
    }
  }
}`;
